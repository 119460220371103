<template>
	<div>

	 <memberlistfull 
	 id ="memberlislorg" 
	 :orgcontext="organization.key" 
	 @onupdated="usersupdated" 
	 @onupdateuserslist="updateuserslist" 
	 :roles="roles" 
	 :inactiveroles="inactiveroles"
	 :adminrole="adminrole"
	 :defaultrole="defaultrole"
	 :myrole ="myrole" 
	 :isactive="true" 
	 :cockpit ="organization"
	 :allowadduser ="true"
	 :hasremove = "false"
	 :hasnodrop = "true"
	 searchtext ="Enter an email address to invite a new user to join the organization."
	 :hasonindicator ="true"
	 :isworking="working"
	 :isadmanaged="isadmanaged"
	 :listtype="'organizationmembers'"
	 :users="members"/>
	</div>


</template>

<style lang = "scss" >
	#memberlislorg{
			#table-objusers{
				.tablerow:first-child{
					border: none;
				}
			}
		}
</style>
<script type="text/javascript"></script>

<script>
	import memberlistfull from '@/components/members/memberlistfull' 
	import {bus} from '@/main'
	export default{
		name: "members",
		data(){
			return{
				 roles:[ { label:"Member", value:"Member", admananged:true, foradmin:true } , { label:"Administrator", value:"Administrator", admananged:true , foradmin:true } , { label:"", value:"", admananged:false} , { label:"Revoke access", value:"Disable", admananged:false } ],
				 inactiveroles:[ { label:"Administrator", value:"inactiveadministrator" } , { label:"Member", value:"inactivemember" }, { label:"" } , { label:"Revoke access", value:"Disable" } ],
				 adminrole: "Administrator",
				 defaultrole: "Member",
				 working: false,
				 members:[]
			}
		},
		mounted(){

			document.title ='Organizations | Flowe'

			let self = this
			var fetchorg = this.$store.dispatch('fechtOrganization',{ token:this.$store.getters.token, key:this.orgkey })

			fetchorg.then( function(){

				//bus.$emit('initmemberslist', self.organization.sharedtousers)
				self.members = self.organization.sharedtousers

			}) 
			
		},
		methods: {


			updateuserslist( users ){

		       this.members = users

		    },

			organizationupdate(field, value, callback){
				var newvalue = value

				this.$store.dispatch('updateOrganization', {field:field, newvalue:newvalue, context:this.organization.key ,t:this.$store.getters.token}).then((response) =>{

					if( callback ){
						callback()
					}

				})

			},

			usersupdated( field, value ){

				this.working = true

				var sharedtousers = []

				let self = this

				for( var i in value ){
					sharedtousers.push( { "userid":value[i].id, "role":value[i].role, "status":value[i].status } )
				}


				this.organizationupdate('sharedtousers', JSON.stringify( sharedtousers ), function( data ){
					self.working = false
				})
			},

			saveusers(){
				this.organization.name = this.editorstate.name.content
				this.organizationupdate('name', this.editorstate.name.content)
				this.editorstate.name.state='idle'
			},
		},
		computed: {
			isadmanaged(){

				return this.organization.requireauth && this.organization.requireauth != '' ? true : false 
			},
			organization(){
				return this.$store.state.organizations.selectedorganizations
			},
			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },
		    myrole(){

				var toreturn = this.$store.state.organizations.selectedorganizations.myrole

				if( this.$store.state.organizations.selectedorganizations.status == "disabled" )  {
					toreturn = "Member"
				}

				return toreturn
			},
		},

		components: {
			memberlistfull,
			
		}
	}
</script>